import React from 'react';
import { FacebookShareButton, TwitterShareButton, LineShareButton } from 'react-share';
import { graphql } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';
import classNames from 'classnames';
import { BackGround } from '../../components/background';
import { BreadCrumb } from '../../components/breadcrumb';
import { Layout } from '../../components/layout';
import { Seo } from '../../components/seo';
import { dayjs } from '../../lib/dayjs';
import { isNewsTypeColor } from '../../lib/is-news-type-color';
import * as CommonStyles from '../../styles/global/_common.module.scss';
import * as Styles from '../../styles/pages/_newsId.module.scss';
import Accent from '/static/accent.svg';
import {Link, useIntl, navigate} from "gatsby-plugin-react-intl"
import {Hitolimits} from "../../components/Hitolimits";
import { contentPathHelper } from '../../utils/contentPathHelper';

const BlogSinglePage = ({ data, location }) => {
  const intl = useIntl()
  const t = (id) => intl.formatMessage({id})
  if (data.microcmsNews.redirect_url) {
    typeof window !== 'undefined' && navigate(data.microcmsNews.redirect_url);
  }

  const relatedArticles = () => {
    if (!data.microcmsNews.relation.length) {
      return [];
    }

    return data.allMicrocmsNews.edges.filter(({ node }) => {
      let check = false;

      node.relation.forEach((relation) => {
        data.microcmsNews.relation.forEach(({ id }) => {
          if (relation.id === id) {
            check = true;
          }
        });
      });

      return check;
    });
  };

  const postDescription = data.microcmsNews.body && data.microcmsNews.body.length >= 1 && data.microcmsNews.body[0].html?.replace(/(<([^>]+)>)/gi, '').substr(0, 70);

  // 記事の最大幅
  const containerSize = 830;

  // 横並び画像の余白
  const gap = 10;

  return (
    !data.microcmsNews.redirect_url && (
      <Layout location={location}>
        <BreadCrumb
          list={[
            {
              title: 'TOP',
              link: '/',
            },
            {
              title: t('menu.news'),
              link: '/news',
            },
          ]}
          currentPage={data.microcmsNews.title}
        />
        <Seo title={`${data.microcmsNews.title}${t('news.seo.title')}`} description={postDescription} image={data.microcmsNews.image.url && data.microcmsNews.image.url + '?w=1200&fm=webp&fit=crop&q=80'} />
        <BackGround>
          <section className={Styles.newsId}>
            <div className={Styles.newsId__wrapper}>
              <div className={classNames(Styles.newsId__inner, CommonStyles.container)}>
                <div className={Styles.newsId__set}>
                  {dayjs(data.microcmsNews.publishedAt).format('YYYY/MM/DD')}
                  <p className={Styles.newsId__newscategory} style={{ backgroundColor: isNewsTypeColor(data.microcmsNews.type[0]) }}>
                    {data.microcmsNews.type[0]}
                  </p>
                </div>
                <h1>{data.microcmsNews.title}</h1>

                <div className={Styles.newsId__image}>
                  <img src={data.microcmsNews.image.url + '?w=1660&q=80&fm=webp'} alt='' />
                </div>

                {data.microcmsNews.body && data.microcmsNews.body.map((content, index) => (
                  <div key={index}>
                    {content.html && (
                      <div
                        className={Styles.newsId__content}
                        dangerouslySetInnerHTML={{
                          __html: content.html,
                        }}
                      />
                    )}
                    {content.images && (
                      <div className={Styles.newsId__images_wrap}>
                        <ul
                          className={Styles.newsId__images_list}
                          style={{
                            gridTemplateColumns: `repeat(auto-fill, minmax(${content.quantity ? `${containerSize / Number(content.quantity) - gap}px` : '140px'}, 1fr))`
                          }}
                        >
                          {content.images.map(({ image, text, link }, index) => (
                            <li key={`image-${index}`}>
                              {link ? (
                                <Link to={link}>
                                  <img src={image.url + "?w=400&h=400&fit=crop&q=80&fm=webp"} alt="" />
                                  <div
                                    className={classNames(Styles.newsId__content, Styles.newsId__images_list__text)}
                                    dangerouslySetInnerHTML={{
                                      __html: text,
                                    }}
                                  />
                                </Link>
                              ) : (
                                <>
                                  <img src={image.url + "?w=400&h=400&fit=crop&q=80&fm=webp"} alt="" />

                                  {text && (
                                    <div
                                      className={classNames(Styles.newsId__content, Styles.newsId__images_list__text)}
                                      dangerouslySetInnerHTML={{
                                        __html: text,
                                      }}
                                    />
                                  )}
                                </>
                              )}
                            </li>)
                          )}
                        </ul>
                      </div>
                    )}
                    {content.hitolimits && (
                      <div>
                        <div className={Styles.entry_work}>
                          <h3>{t('hitolimits.competitionEntry')}</h3>
                          <p>
                            <strong>_____________________________________________________________________________</strong><br/>
                          </p>
                        </div>
                        <div className={Styles.hitolimits_flex_container}>
                          {content.hitolimits.map((e, index) => (
                              <Hitolimits hitolimitsData={e.hitolimits} />
                          ))}
                        </div>
                      </div>
                    )}
                  </div>
                ))}

                <p className={Styles.newsId__btn}>
                  <Link to={`/news/`}>{t('news.returnNews')}</Link>
                </p>
                <div className={Styles.newsId__share}>
                  <TwitterShareButton url={location.href} title={data.microcmsNews.title}>
                    <StaticImage src='../../images/twitter_share.png' alt='Twitter Share' />
                  </TwitterShareButton>
                  <FacebookShareButton url={location.href} title={data.microcmsNews.title}>
                    <StaticImage src='../../images/facebook_share.png' alt='facebook Share' />
                  </FacebookShareButton>
                  <LineShareButton url={location.href} title={data.microcmsNews.title}>
                    <StaticImage src='../../images/line_share.png' alt='Line Share' />
                  </LineShareButton>
                </div>

                <h2>
                  <img src={Accent} className='accent' alt='accent' />
                  {t('news.relatedArticles')}
                </h2>

                {data.microcmsNews.relation.length ? (
                  <ul className={Styles.newsId__list}>
                    {relatedArticles()
                      .slice(0, 3)
                      .map(({ node }) => (
                        <li key={node.newsId} className={Styles.newsId__list_card}>
                          <Link to={`/news/${contentPathHelper(node.newsId)}/`}>
                            <img src={node.image.url + "?w=522&h=298&fit=crop&q=80&fm=webp"} alt='' />
                            <div className={Styles.newsId__boxwrap}>
                              <div className={Styles.newsId__set}>
                                {dayjs(node.publishedAt).format('YYYY/MM/DD')}{' '}
                                <p className={Styles.newsId__newscategory} style={{ backgroundColor: isNewsTypeColor(node.type[0]) }}>
                                  {node.type[0]}
                                </p>
                              </div>
                              <p className={Styles.newsId__newstitle}>{node.title}</p>
                            </div>
                          </Link>
                        </li>
                      ))}
                  </ul>
                ) : (
                  <p>{t('news.noArticles')}</p>
                )}
              </div>
            </div>
          </section>
        </BackGround>
      </Layout>
    )
  );
};

export const query = graphql`
  query ($newsId: String) {
    microcmsNews(newsId: { eq: $newsId }) {
      title
      publishedAt
      body {
        html
        quantity
        images {
          image {
            url
          }
          link
          text
        }
        hitolimits {
          hitolimits {
            id
            artist {
              id
            }
            youtube_url
            vote_start_date
            vote_point
            vote_end_datetime
            concrete
            description
            abstract
          }
        }
      }
      redirect_url
      image {
        url
      }
      type
      relation {
        id
        description
      }
    }

    allMicrocmsNews(sort: { fields: [publishedAt], order: ASC }) {
      edges {
        node {
          newsId
          image {
            url
          }
          title
          publishedAt
          type
          relation {
            id
            description
          }
        }
      }
    }
  }
`;

export default BlogSinglePage;
