// extracted by mini-css-extract-plugin
export var flex_container = "_hitolimites_vote-module--flex_container--hg1zQ";
export var flex_item = "_hitolimites_vote-module--flex_item--xPpIf";
export var main__content = "_hitolimites_vote-module--main__content--9DUPC";
export var artists_data = "_hitolimites_vote-module--artists_data--clEPz";
export var amount__img = "_hitolimites_vote-module--amount__img--kk9FU";
export var artists__detail = "_hitolimites_vote-module--artists__detail--dyQpk";
export var __name = "_hitolimites_vote-module--__name--w3yiI";
export var __detail = "_hitolimites_vote-module--__detail--s7XU1";
export var theme = "_hitolimites_vote-module--theme--c3Ak7";
export var description = "_hitolimites_vote-module--description--T9gjW";
export var embed_responsive = "_hitolimites_vote-module--embed_responsive--+HF--";
export var btn_vote = "_hitolimites_vote-module--btn_vote--ZAp3z";
export var active = "_hitolimites_vote-module--active--BPa2E";
export var disable = "_hitolimites_vote-module--disable--sDO-4";
export var like_count = "_hitolimites_vote-module--like_count--3K6DN";
export var vote_bar = "_hitolimites_vote-module--vote_bar--75Kve";
export var vote_bar_ng = "_hitolimites_vote-module--vote_bar_ng--ZrV6p";