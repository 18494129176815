// extracted by mini-css-extract-plugin
export var newsId = "_newsId-module--newsId--Vycbx";
export var newsId__inner = "_newsId-module--newsId__inner--l7Nx7";
export var newsId__wrapper = "_newsId-module--newsId__wrapper--PSAAg";
export var newsId__wrapper__set = "_newsId-module--newsId__wrapper__set--9fvOB";
export var newsId__wrapper__newscategory = "_newsId-module--newsId__wrapper__newscategory--0NjPO";
export var newsId__image = "_newsId-module--newsId__image--wxwJK";
export var newsId__btn = "_newsId-module--newsId__btn--oQlWA";
export var newsId__share = "_newsId-module--newsId__share--6ZCnZ";
export var newsId__bg_bottom = "_newsId-module--newsId__bg_bottom--nZjbp";
export var newsId__list = "_newsId-module--newsId__list--Nk2yG";
export var newsId__list_card = "_newsId-module--newsId__list_card--HKsTc";
export var newsId__boxwrap = "_newsId-module--newsId__boxwrap--6rjoG";
export var newsId__set = "_newsId-module--newsId__set--drKxR";
export var newsId__newscategory = "_newsId-module--newsId__newscategory--wvi27";
export var newsId__newstitle = "_newsId-module--newsId__newstitle--1Xtwd";
export var newsId__content = "_newsId-module--newsId__content--VYxfY";
export var aligncenter = "_newsId-module--aligncenter--6anuv";
export var alignleft = "_newsId-module--alignleft--6z34J";
export var alignright = "_newsId-module--alignright--8ix4W";
export var newsId__images_title = "_newsId-module--newsId__images_title--E3QwY";
export var newsId__images_list = "_newsId-module--newsId__images_list--rqJCC";
export var newsId__images_list__text = "_newsId-module--newsId__images_list__text--Dsmre";
export var newsId__images_wrap = "_newsId-module--newsId__images_wrap--OZ4JO";
export var entry_work = "_newsId-module--entry_work--dpRPt";
export var hitolimits_flex_container = "_newsId-module--hitolimits_flex_container--lGDjH";