import * as React from 'react';
import PropTypes from 'prop-types';
import { graphql, useStaticQuery  } from 'gatsby';
import * as Styles from '../styles/components/_hitolimites_vote.module.scss';
import {useIntl} from "gatsby-plugin-react-intl";
import axios from "axios";
import moment from 'moment'

const getMicroCMSData = async (hitolimitsId) => {
  const serviceId = process.env.MICROCMS_SERVICE_ID
  const apiKey = process.env.API_KEY
  return await axios.get(`https://${serviceId}.microcms.io/api/v1/hitolimits/${hitolimitsId}`, {
    headers: {
      'Content-Type': 'application/json',
      'X-MICROCMS-API-KEY': apiKey
    }
  }).then(response => {
    return response
  })
}

const voteArtist = async ({ hitolimitsId, intl }) => {
  const t = (id) => intl.formatMessage({id})

  const apiKey = process.env.LIMITS_API_KEY
  const apiUrl = process.env.LIMITS_API_URL

  const button = document.getElementById(`vote-button-${hitolimitsId}`)
  if (button) {
    button.innerText = t('hitolimits.voting')
    button.style.background = '#57a7f5'
    button.style.pointerEvents = 'none'
  }

  await axios
    .post(apiUrl + `/api/hitolimits/voting/${hitolimitsId}`, {
      headers: {
        'Content-Type': 'application/json',
        'LIMITS-BATTLESYSTEM-API-KEY': apiKey
      }
    })
    .then((response) => {
      // alert('voted ' + hitolimitsId)
      button.innerText = t('hitolimits.voted')
      button.style.background = '#68cc27'
      button.style.pointerEvents = 'none'
      getMicroCMSData(hitolimitsId).then(r => {
        const element = document.getElementById(`vote-${hitolimitsId}`)
        element.innerText = r.data.vote_point
      })
    })
    .catch((error) => {
      console.error(error)
      button.innerText = t('hitolimits.voteFailed')
      button.style.background = '#666'
      button.style.pointerEvents = 'none'
      setTimeout(() => {
        button.innerText = t('hitolimits.vote')
        button.style.background = '#9061db'
        button.style.pointerEvents = 'auto'
      }, 5000)
    });
}

export const Hitolimits = ({ hitolimitsData }) => {
  const intl = useIntl()
  const t = (id) => intl.formatMessage({id})

  // アーティスト情報取得
  const { allMicrocmsArtist } = useStaticQuery(
    graphql`
      query {
        allMicrocmsArtist {
          edges {
            node {
              id
              artistId
              image_url
              image {
                url
              }
              name
              youtube
              description
            }
          }
        }
      }
    `
  );
  const artist = allMicrocmsArtist.edges.filter(a => a.node.artistId === hitolimitsData.artist.id)
  const microcmsArtist = artist[0].node

  // 投票期間チェック
  const isBetween = moment().isBetween(hitolimitsData.vote_start_date, hitolimitsData.vote_end_datetime)

  return (
    <div className={Styles.flex_item}>
      <div className={Styles.embed_responsive}>
        <iframe allowFullScreen="" className={Styles.embed_responsive_item} src={hitolimitsData.youtube_url} />
      </div>
      <div className={Styles.main__content}>
        <div className={Styles.artists_data}>
          <div className={Styles.amount__img}>
            <img
              src={microcmsArtist.image_url || microcmsArtist.image.url}
              alt={microcmsArtist.name}
            />
          </div>
          <div className={Styles.artists__detail}>
            <p className={Styles.__name}>{microcmsArtist.name}</p>
            <p
              className={Styles.__detail}
              dangerouslySetInnerHTML={{
                __html: `<div> ${microcmsArtist.description} </div>`,
              }}
            />
          </div>
        </div>
        <div className={Styles.theme}>
          <span>{t('hitolimits.theme')}</span>{hitolimitsData.abstract} x {hitolimitsData.concrete}
        </div>
        <div className={Styles.description}>
          {hitolimitsData.description}
        </div>
      </div>
      <div className={Styles.btn_vote}>
        {isBetween && (
          <a
            id={`vote-button-${hitolimitsData.id}`}
            className={Styles.active}
            onClick={() => voteArtist({hitolimitsId: hitolimitsData.id, intl})}
          >
            {t('hitolimits.vote')}
          </a>
        )}
        {!isBetween && (
          <a className={Styles.disable}>
            {t('hitolimits.outOfRange')}
          </a>
        )}
      </div>

      <div className={Styles.like_count}>
        <span>{t('hitolimits.favorite')}</span>
        <span id={`vote-${hitolimitsData.id}`}></span>
        <span>{t('hitolimits.point')}</span>
      </div>
    </div>
  );
};

Hitolimits.propTypes = {
  siteTitle: PropTypes.string,
};

Hitolimits.defaultProps = {
  siteTitle: ``,
};

export default Hitolimits;
